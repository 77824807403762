import React, { Component } from "react";

class Music extends Component {
    render() {
        return (
            <h1>Music page</h1>
        );
    }
}

export default Music