import React, { Component } from "react";

class Experience extends Component {
    render() {
        return (
            <h1>Experience page</h1>
        );
    }
}

export default Experience